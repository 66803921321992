




































import { Component, Vue } from 'vue-property-decorator'
import { MessageInfoPage } from '@/types/message'

@Component({
  name: 'List',
  filters: {
    filtersStatus (value: string) {
      return value === '0'
        ? '未发送'
        : value === '1'
          ? '已发送'
          : ''
    }
  }
})
export default class List extends Vue {
  private loading = false
  private tableData: MessageInfoPage[] = []
  private page = 1
  private size = 10
  private total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<MessageInfoPage> }>(this.$apis.message.selectMessageByPage, {
      isMessage: '1',
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      })
      .finally(() => {
        this.loading = false
      })
  }

  onAdd () {
    this.$router.push({ name: 'messageAdd' })
  }

  onUpdate (id: string) {
    this.$router.push({ name: 'messageUpdate', params: { id } })
  }

  onDetail (id: string) {
    this.$router.push({ name: 'messageDetail', params: { id } })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.message.deleteMessage, {
            messageId: id
          })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 发布
  onRelease (id: string, status: string) {
    this.$axios.post(this.$apis.message.releaseMessage, {
      messageId: id,
      status: status
    }).then(() => {
      this.$message.success('操作成功')
      this.getData()
    })
  }
}
